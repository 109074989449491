import AppLink from '@theme/components/atom/AppLink'
import Logo from '@theme/components/molecule/Logo'

export default {
  components: {
    AppLink,
    Logo,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
  },
}
